//
// Component: Slidenav
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-slidenav() { transition: color 0.1s ease-in-out; }

.hook-slidenav-hover() {}

.hook-slidenav-active() {}


// Icon modifier
// ========================================================================

.hook-slidenav-previous() {}

.hook-slidenav-next() {}


// Size modifier
// ========================================================================

.hook-slidenav-large() {}


// Container
// ========================================================================

.hook-slidenav-container() {}


// Miscellaneous
// ========================================================================

.hook-icon-misc() {}


// Inverse
// ========================================================================

.hook-inverse-slidenav() {}
.hook-inverse-slidenav-hover() {}
.hook-inverse-slidenav-active() {}