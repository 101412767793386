//
// Component: Form
//
// ========================================================================

// Variables
// ========================================================================

@form-placeholder-color: fade(@global-emphasis-color, 50%);

@form-line-height: @form-height - (2 * @form-border-width);

@form-background: @global-muted-background;
@form-focus-background: darken(@global-muted-background, 5%);

@form-small-line-height: @form-small-height - (2 * @form-border-width);
@form-large-line-height: @form-large-height - (2 * @form-border-width);

@form-radio-background: @global-dark-grey-color-5;
@form-radio-disabled-background: lighten(@form-radio-background, 10%);

@form-stacked-margin-bottom: 5px;

@form-padding-horizontal: 20px;
@form-padding-vertical: 20px;
@form-small-padding-horizontal: 20px;
@form-large-padding-horizontal: 20px;

@form-select-padding-right: 24px;
@form-datalist-padding-right: 24px;

//
// New
//

@form-font-size: @global-font-size;
@form-small-font-size: @global-small-font-size;
@form-large-font-size: @global-large-font-size;

@form-border-width: 0;
@form-border: @global-border;
@form-border-radius: @global-border-radius;

@form-focus-border: @global-primary-background;

@form-disabled-border: @global-border;

@form-danger-border: @global-danger-background;
@form-success-border: @global-success-background;

@form-blank-focus-border: @global-border;
@form-blank-focus-border-style: dashed;

@form-radio-focus-border: @global-primary-background;

@form-radio-checked-border: transparent;

@form-radio-disabled-border: @global-border;

@form-label-color: @global-emphasis-color;
@form-label-font-size: @global-small-font-size;

@form-radio-size: 22px;
@form-radio-size-small: 16px;
@form-radio-size-large: 28px;

@form-radio-icon-size-small: 20px;
@form-radio-icon-size-large: 30px;

@form-checkbox-icon-size-small: 14px;
@form-checkbox-icon-size-large: 20px;

@form-radio-border-radius: 6px;
@form-radio-border-radius-small: 4px;
@form-radio-border-radius-large: 8px;

@checkbox-font-size-large: @global-medium-font-size;

@internal-form-checkbox-tree-opened: "../../images/backgrounds/checkbox-tree-opened.svg";
@internal-form-checkbox-tree-closed: "../../images/backgrounds/checkbox-tree-closed.svg";
@internal-form-radio-image: "../../images/backgrounds/form-radio.svg";
@internal-form-radio-image-custom: "../../images/backgrounds/form-radio-acr.svg";


// Component
// ========================================================================

.hook-form() {
  font-size: @form-font-size;
  border: @form-border-width solid @form-border;
  border-radius: @form-border-radius;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
}

.hook-form-single-line() {
}

.hook-form-multi-line() {
}

.hook-form-focus() {
  border-color: @form-focus-border;
}

.hook-form-disabled() {
  border-color: @form-disabled-border;
}

// Style modifiers
// ========================================================================

.hook-form-danger() {
  border-color: @form-danger-border;
}

.hook-form-success() {
  border-color: @form-success-border;
}

.hook-form-blank() {
  border-color: transparent;
}

.hook-form-blank-focus() {
  border-color: @form-blank-focus-border;
  border-style: @form-blank-focus-border-style;
}

// Radio and checkbox
// ========================================================================

.hook-form-radio() {
  border-radius: @form-radio-border-radius;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  min-width: @form-radio-size;
}

.hook-form-radio-focus() {
  border-color: @form-radio-focus-border;
}

.hook-form-radio-checked() {
  border-color: @form-radio-checked-border;
}

.hook-form-radio-checked-focus() {
}

.hook-form-radio-disabled() {
  border-color: @form-radio-disabled-border;
}

// Legend
// ========================================================================

.hook-form-legend() {
}

// Label
// ========================================================================

.hook-form-label() {
  color: @form-label-color;
  font-size: @form-label-font-size;
}

// Layout
// ========================================================================

.hook-form-stacked-label() {
}

.hook-form-horizontal-label() {
}

// Miscellaneous
// ========================================================================

.hook-form-misc() {
  .uk-select:not([multiple]):not([size]),
  .uk-input[list],
  .uk-input[list]:hover,
  .uk-input[list]:focus {
    // 15px allows to create a smaller padding on the left of the icon
    .svg-fill(@internal-form-select-image, "#000", @form-select-icon-color);
    background-size: @form-select-padding-right, @form-select-padding-right;
  }

  .uk-select:not([multiple]):not([size]):disabled,
  .uk-input[list]:disabled {
    // 15px allows to create a smaller padding on the left of the icon
    .svg-fill(@internal-form-select-image, "#000", @form-select-disabled-icon-color);
    background-size: @form-select-padding-right, @form-select-padding-right;
  }

  .uk-select.uk-form-large:not(textarea):not([multiple]):not([size]),
  .uk-select.uk-form-small:not(textarea):not([multiple]):not([size]) {
    padding-right: @form-select-padding-right;
  }

  .uk-checkbox-small,
  .uk-radio-small,
  .uk-radio-acr-small {
    height: @form-radio-size-small;
    width: @form-radio-size-small;
    min-width: @form-radio-size-small;
    border-radius: @form-radio-border-radius-small;
    line-height: @form-radio-size-small;
  }

  .uk-checkbox-large,
  .uk-radio-large,
  .uk-radio-acr-large {
    height: @form-radio-size-large;
    width: @form-radio-size-large;
    min-width: @form-radio-size-large;
    border-radius: @form-radio-border-radius-large;
    font-size: @checkbox-font-size-large;
    line-height: @form-radio-size-large;
  }

  .uk-radio,
  .uk-radio-acr {
    border-radius: @form-radio-size / 2;
  }
  .uk-radio-small,
  .uk-radio-acr-small {
    border-radius: @form-radio-size-small / 2;
  }
  .uk-radio-large,
  .uk-radio-acr-large {
    border-radius: @form-radio-size-large / 2;
  }

  .uk-radio-small:checked {
    .svg-fill(@internal-form-radio-image, "#000", @form-radio-checked-icon-color);
  }

  .uk-radio-acr-small:checked {
    .svg-fill(@internal-form-radio-image-custom, "#000", @form-radio-checked-icon-color-custom);
  }

  .uk-radio-small:checked,
  .uk-radio-acr-small:checked {
    background-size: @form-radio-icon-size-small @form-radio-icon-size-small;
  }
  .uk-checkbox-small:checked {
    .svg-fill(@internal-form-checkbox-image, "#000", @form-radio-checked-icon-color);
    background-size: @form-checkbox-icon-size-small
      @form-checkbox-icon-size-small;
  }
  .uk-checkbox-small:indeterminate {
    .svg-fill(@internal-form-checkbox-indeterminate-image, "#000", @form-radio-checked-icon-color);
    background-size: @form-checkbox-icon-size-small
      @form-checkbox-icon-size-small;
  }

  .uk-radio-small:disabled:checked {
    .svg-fill(@internal-form-radio-image, "#000", @form-radio-disabled-icon-color);
  }

  .uk-radio-acr-small:disabled:checked {
    .svg-fill(@internal-form-radio-image-custom, "#000", @form-radio-disabled-icon-color);
  }

  .uk-radio-small:disabled:checked,
  .uk-radio-acr-small:disabled:checked {
    background-size: @form-radio-icon-size-small @form-radio-icon-size-small;
  }
  .uk-checkbox-small:disabled:checked {
    .svg-fill(@internal-form-checkbox-image, "#000", @form-radio-disabled-icon-color);
    background-size: @form-checkbox-icon-size-small
      @form-checkbox-icon-size-small;
  }
  .uk-checkbox-small:disabled:indeterminate {
    .svg-fill(@internal-form-checkbox-indeterminate-image, "#000", @form-radio-disabled-icon-color);
    background-size: @form-checkbox-icon-size-small
      @form-checkbox-icon-size-small;
  }

  .uk-radio-large:checked {
    .svg-fill(@internal-form-radio-image, "#000", @form-radio-checked-icon-color);
  }

  .uk-radio-acr-large:checked {
    .svg-fill(@internal-form-radio-image-custom, "#000", @form-radio-checked-icon-color-custom);
  }

  .uk-radio-large:checked,
  .uk-radio-acr-large:checked {
    background-size: @form-radio-icon-size-large @form-radio-icon-size-large;
  }
  .uk-checkbox-large:checked {
    .svg-fill(@internal-form-checkbox-image, "#000", @form-radio-checked-icon-color);
    background-size: @form-checkbox-icon-size-large
      @form-checkbox-icon-size-large;
  }
  .uk-checkbox-large:indeterminate {
    .svg-fill(@internal-form-checkbox-indeterminate-image, "#000", @form-radio-checked-icon-color);
    background-size: @form-checkbox-icon-size-large
      @form-checkbox-icon-size-large;
  }

  .uk-radio-large:disabled:checked {
    .svg-fill(@internal-form-radio-image, "#000", @form-radio-disabled-icon-color);
  }
  .uk-radio-acr-large:disabled:checked {
    .svg-fill(@internal-form-radio-image-custom, "#000", @form-radio-disabled-icon-color);
  }

  .uk-radio-large:disabled:checked,
  .uk-radio-acr-large:disabled:checked {
    background-size: @form-radio-icon-size-large @form-radio-icon-size-large;
  }
  .uk-checkbox-large:disabled:checked {
    .svg-fill(@internal-form-checkbox-image, "#000", @form-radio-disabled-icon-color);
    background-size: @form-checkbox-icon-size-large
      @form-checkbox-icon-size-large;
  }
  .uk-checkbox-large:disabled:indeterminate {
    .svg-fill(@internal-form-checkbox-indeterminate-image, "#000", @form-radio-disabled-icon-color);
    background-size: @form-checkbox-icon-size-large
      @form-checkbox-icon-size-large;
  }

  .uk-checkbox-tree-large {
    display: flex;
    flex-direction: column;
    margin: -5px 0 0 0;

    .uk-checkbox {
      margin: 0 15px 0 0;
    }

    label {
      display: flex;
      justify-content: flex-start;
      line-height: 28px;
      margin: 5px 0 5px 0;
    }

    .uk-parent .uk-checkbox-title {
      display: flex;
      flex-direction: row;
      margin: 5px 0 5px 0;
      align-items: center;

      &.uk-open label::after {
        .svg-fill(@internal-form-checkbox-tree-opened, "#000", #FFF);
      }

      label {
        flex: 1;
        position: relative;
        padding-right: 30px;
        margin: 0;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          .svg-fill(@internal-form-checkbox-tree-closed, "#000", #FFF);
          background-size: 20px 20px;
          width: 20px;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .uk-checkbox-tree-sub {
      display: flex;
      flex-direction: column;
      transition: height 0.2s ease;
      padding: 0 0 0 (28px + 10px);
      height: 0;
      overflow: hidden;
    }

    .uk-open + .uk-checkbox-tree-sub {
      height: initial;
    }
  }
}

// Inverse
// ========================================================================

@inverse-form-label-color: @inverse-global-emphasis-color;

.hook-inverse-form() {
  border-color: @inverse-global-border;
}
.hook-inverse-form-focus() {
  border-color: @inverse-global-color;
}

.hook-inverse-form-radio() {
  border-color: @inverse-global-border;
}
.hook-inverse-form-radio-focus() {
  border-color: @inverse-global-color;
}

.hook-inverse-form-radio-checked() {
  border-color: @inverse-global-color;
}
.hook-inverse-form-radio-checked-focus() {
}

.hook-inverse-form-label() {
  color: @inverse-form-label-color;
}

// custom component

.uk-custom-select-and-search {
  width: 750px;
  background-color: @global-light-grey-color-1;
  position: relative;

  .uk-select {
    width: 200px;
    height: 80px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: @global-dark-grey-color-2;
  }
  .uk-input {
    border-left: 2px solid @global-dark-grey-color-1;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: @global-dark-grey-color-2;
    height: 80px;
  }
}
