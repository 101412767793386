//
// Component: Utility
//
// ========================================================================


// Variables
// ========================================================================


// Panel
// ========================================================================

.hook-panel-scrollable() {}


// Box-shadow bottom
// ========================================================================

.hook-box-shadow-bottom() {}


// Drop cap
// ========================================================================

.hook-dropcap() {
    // Prevent line wrap
    margin-bottom: -2px;
}


// Logo
// ========================================================================

.hook-logo() {}

.hook-logo-hover() {}


// Miscellaneous
// ========================================================================

.hook-utility-misc() {}


// Inverse
// ========================================================================

.hook-inverse-dropcap() {}

.hook-inverse-logo() {}
.hook-inverse-logo-hover() {}