//
// Component: Search
//
// ========================================================================


// Variables
// ========================================================================

@search-default-background:                         transparent;

//
// New
//

@search-default-border-width:                       @global-border-width;
@search-default-border:                             @global-border;


// Component
// ========================================================================

.hook-search-input() {}


// Default modifiers
// ========================================================================

.hook-search-default-input() { border: @search-default-border-width solid @search-default-border; }


// Navbar modifiers
// ========================================================================

.hook-search-navbar-input() {}

.hook-search-default-input-focus() {}


// Large modifiers
// ========================================================================

.hook-search-large-input() {}


// Toggle
// ========================================================================

.hook-search-toggle() {}

.hook-search-toggle-hover() {}


// Miscellaneous
// ========================================================================

.hook-search-misc() {}


// Inverse
// ========================================================================

@inverse-search-default-background:            transparent;

.hook-inverse-search-default-input() { border-color: @inverse-global-border; }
.hook-inverse-search-default-input-focus() {}

.hook-inverse-search-navbar-input() {}

.hook-inverse-search-large-input() {}

.hook-inverse-search-toggle() {}
.hook-inverse-search-toggle-hover() {}