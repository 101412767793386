//
// Component: Form Range
//
// ========================================================================


// Variables
// ========================================================================

@form-range-thumb-background:                   @global-background;

//
// New
//

@form-range-thumb-border-width:                 @global-border-width;
@form-range-thumb-border:                       darken(@global-border, 10%);

@form-range-track-border-radius:                500px;


// Component
// ========================================================================

.hook-form-range() {}


// Thumb
// ========================================================================

.hook-form-range-thumb() { border: @form-range-thumb-border-width solid @form-range-thumb-border; }


// Track
// ========================================================================

.hook-form-range-track() { border-radius: @form-range-track-border-radius; }

.hook-form-range-track-focus() {}


// Miscellaneous
// ========================================================================

.hook-form-range-misc() {}