//
// Component: Article
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@article-meta-link-color:                       @article-meta-color;
@article-meta-link-hover-color:                 @global-color;


// Component
// ========================================================================

.hook-article() {}


// Adjacent sibling
// ========================================================================

.hook-article-adjacent() {}


// Title
// ========================================================================

.hook-article-title() {}


// Meta
// ========================================================================

.hook-article-meta() {

    a { color: @article-meta-link-color; }

    a:hover {
        color: @article-meta-link-hover-color;
        text-decoration: none;
    }

}


// Miscellaneous
// ========================================================================

.hook-article-misc() {}


// Inverse
// ========================================================================

.hook-inverse-article-meta() {}