//
// Component: List
//
// ========================================================================


// Variables
// ========================================================================
@list-margin-top:                               0;


//
// New
//

@list-striped-border-width:                     @global-border-width;
@list-striped-border:                           @global-border;
@list-padding-item:                             9px 9px 9px 9px;
@list-background-active:                        fade(@global-muted-color,20%);
@list-border-radius-active:                     21px;
@list-font-size:                                @global-font-size;
@list-color-active:                             @global-emphasis-color;
@list-color-inactive:                           @global-muted-color;
@list-line-height:                              24px;
@list-img-height:                               24px;
@list-img-width:                                24px;
@list-padding-list:                             0 36px 0 14px;

// Style modifiers
// ========================================================================

.hook-list-divider() {}

.hook-list-striped() {

    &:nth-of-type(odd) {
        border-top: @list-striped-border-width solid @list-striped-border;
        border-bottom: @list-striped-border-width solid @list-striped-border;
    }

}

.hook-list-bullet() {}


// Miscellaneous
// ========================================================================

.hook-list-misc() {
    .uk-list>li>span>svg {
        height: @list-img-height;
        width: @list-img-width;
        max-width: unset;
    }
    .uk-list {
        padding: @list-padding-list;
    }
    .uk-list>li, .uk-list>div>li{
        padding: @list-padding-item;
        cursor: pointer;
        font-size: @list-font-size;
        line-height: @list-line-height;
        &.uk-active,&:hover{
            background-color: @list-background-active;
            color: @list-color-active;
            border-radius: @list-border-radius-active;
        }
        &.uk-inactive{
            color: @list-color-inactive;
        }
    }

}


// Inverse
// ========================================================================

.hook-inverse-list-divider() {}
.hook-inverse-list-striped() {

    &:nth-of-type(odd) {
        border-top-color: @inverse-global-border;
        border-bottom-color: @inverse-global-border;
    }

}
.hook-inverse-list-bullet() {}