//
// Component: Label
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@label-border-radius:                           2px;
@label-text-transform:                          uppercase;


// Component
// ========================================================================

.hook-label() {
    border-radius: @label-border-radius;
    text-transform: @label-text-transform;
}


// Color modifiers
// ========================================================================

.hook-label-success() {}

.hook-label-warning() {}

.hook-label-danger() {}


// Miscellaneous
// ========================================================================

.hook-label-misc() {}


// Inverse
// ========================================================================

.hook-inverse-label() {}