// Name:            Leader
// Description:     Component to create dot leaders
//
// Component:       `uk-leader`
//
// ========================================================================


// Variables
// ========================================================================

@leader-fill-content:                           ~'.';
@leader-fill-margin-left:                       @global-small-gutter;


/* ========================================================================
   Component: Leader
 ========================================================================== */

.uk-leader { overflow: hidden; }

/*
 * 1. Place element in text flow
 * 2. Never break into a new line
 * 3. Get a string back with as many repeating characters to fill the container
 * 4. Prevent wrapping. Overflowing characters will be clipped by the container
 */

.uk-leader-fill::after {
    /* 1 */
    display: inline-block;
    margin-left: @leader-fill-margin-left;
    /* 2 */
    width: 0;
    /* 3 */
    content: attr(data-fill);
    /* 4 */
    white-space: nowrap;
    .hook-leader;
}

/*
 * Hide if media does not match
 */

.uk-leader-fill.uk-leader-hide::after { display: none; }

/*
 * Pass fill character to JS
 */

.uk-leader-fill-content::before { content: '@{leader-fill-content}'; }
:root { --uk-leader-fill-content: @leader-fill-content; }


// Hooks
// ========================================================================

.hook-leader-misc;

.hook-leader() {}
.hook-leader-misc() {}


// Inverse
// ========================================================================

.hook-inverse() {

    .uk-leader-fill::after {
        .hook-inverse-leader;
    }

}

.hook-inverse-leader() {}
