//
// Component: Marker
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-marker() {
    border-radius: 500px;
}

.hook-marker-hover() {}


// Miscellaneous
// ========================================================================

.hook-marker-misc() {}


// Inverse
// ========================================================================

.hook-inverse-marker() {}
.hook-inverse-marker-hover() {}
