//
// Component: Totop
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-totop() { transition: color 0.1s ease-in-out; }

.hook-totop-hover() {}

.hook-totop-active() {}


// Miscellaneous
// ========================================================================

.hook-icon-misc() {}


// Inverse
// ========================================================================

.hook-inverse-totop() {}
.hook-inverse-totop-hover() {}
.hook-inverse-totop-active() {}