//
// Component: Pagination
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-pagination() {}


// Items
// ========================================================================

.hook-pagination-item() { transition: color 0.1s ease-in-out; }

.hook-pagination-item-hover() {}

.hook-pagination-item-active() {}

.hook-pagination-item-disabled() {}


// Miscellaneous
// ========================================================================

.hook-pagination-misc() {}


// Inverse
// ========================================================================

.hook-inverse-pagination-item() {}
.hook-inverse-pagination-item-hover() {}
.hook-inverse-pagination-item-active() {}
.hook-inverse-pagination-item-disabled() {}