//
// Component: Comment
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@comment-primary-padding:                       @global-gutter;
@comment-primary-background:                    @global-muted-background;


// Component
// ========================================================================

.hook-comment() {}


// Sections
// ========================================================================

.hook-comment-body() {}

.hook-comment-header() {}


// Title
// ========================================================================

.hook-comment-title() {}


// Meta
// ========================================================================

.hook-comment-meta() {}


// Avatar
// ========================================================================

.hook-comment-avatar() {}


// List
// ========================================================================

.hook-comment-list-adjacent() {}

.hook-comment-list-sub() {}

.hook-comment-list-sub-adjacent() {}


// Style modifier
// ========================================================================

.hook-comment-primary() {
    padding: @comment-primary-padding;
    background-color: @comment-primary-background;
}


// Miscellaneous
// ========================================================================

.hook-comment-misc() {}