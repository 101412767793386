//
// Component: Close
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-close() {
    transition: 0.1s ease-in-out;
    transition-property: color, opacity;
}

.hook-close-hover() {}


// Miscellaneous
// ========================================================================

.hook-close-misc() {}


// Inverse
// ========================================================================

.hook-inverse-close() {}
.hook-inverse-close-hover() {}