//
// Component: Tab
//
// ========================================================================


// Variables
// ========================================================================

@tab-margin-horizontal:                         0;
@tab-item-padding-horizontal:                   0;
@tab-item-padding-vertical:                     0;

//
// New
//

@tab-height:                                    38px;

@tab-border-width:                              2px;
@tab-border:                                    @global-dark-grey-color-1;

@tab-item-margin-vertical:                      10px;
@tab-item-margin-horizontal:                    5px;

@tab-padding-horizontal:                        25px - @tab-item-margin-horizontal;
@tab-padding-vertical:                          25px;

@tab-vertical-item-padding-horizontal:          20px;

@tab-item-border-width:                         4px;
@tab-item-font-size:                            @global-small-font-size;
@tab-item-font-weight:                          600;
@tab-item-text-transform:                       none;
@tab-item-active-border:                        @global-yellow-color;

@tab-page-item-width:                           100px;
@tab-page-item-width-large:                     100px;
@tab-page-item-height:                          38px;
@tab-page-image:                                "../../images/backgrounds/tab-page.svg";
@tab-first-page-image:                          "../../images/backgrounds/tab-page-first.svg";
@tab-first-page-image-large:                    "../../images/backgrounds/tab-page-first-large.svg";
@tab-second-page-image-large:                    "../../images/backgrounds/tab-page-second-large.svg";
@tab-page-color:                                @global-emphasis-color;
@tab-page-item-text-transform:                  uppercase;
@tab-page-item-width-active:                    208px;
@tab-page-item-width-active-large:              445px;
@tab-page-item-font-size:                       13px;
@tab-page-item-text-weight:                     bold;

@tab-page-default-active-background:            @global-dark-grey-color-2;
@tab-page-primary-active-background:            @global-dark-grey-color-3;

// Component
// ========================================================================

.hook-tab() {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    height: @tab-height;
    min-height: @tab-height;

    & > * {
        cursor: pointer;
        margin: 0 @tab-item-margin-horizontal 0 @tab-item-margin-horizontal;
    }

    &::before {
        bottom: 0;
    }

    &:not(.uk-tab-left):not(.uk-tab-right) {
        &:not(.uk-tab-page-primary):not(.uk-tab-page-default):not(.uk-tab-page-secondary):not(.uk-tab-page-secondary-large)  {
            padding: 0 @tab-padding-horizontal 0 @tab-padding-horizontal;
            
            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                border-bottom: @tab-border-width solid @tab-border;
            }
    
            & > * {
                display: flex;
                justify-content: center;
                
                // this may seem ugly and useless but it's actually very useful 
                // when the container of a uk-tab is scrollable, in this way we keep 
                // a border on the bottom, that otherwise would disappear on scroll
                &::before {
                    content: "";
                    position: absolute;
                    border-bottom: @tab-border-width solid @tab-border;
                    left: -@tab-item-margin-horizontal;
                    right: -@tab-item-margin-horizontal;
                }
            }
        }

        &:not(.uk-tab-bottom) > *::before {
            bottom: 0;
        }
    }
}


// Items
// ========================================================================

.hook-tab-item() {
    border-bottom: @tab-item-border-width solid transparent;
    font-size: @tab-item-font-size;
    font-weight: @tab-item-font-weight;
    text-transform: @tab-item-text-transform;
    transition: color 0.1s ease-in-out;
}

.hook-tab-item-hover() {}

.hook-tab-item-active() { 
    border: none; 

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: @tab-margin-horizontal;
        right: 0;
        height: @tab-item-border-width;
        background-color: @tab-item-active-border;
        border-top-left-radius: @tab-item-border-width;
        border-top-right-radius: @tab-item-border-width;
    }
}

.hook-tab-item-disabled() {}


// Position modifiers
// ========================================================================

.hook-tab-bottom() {
    &::before {
        top: 0;
        bottom: auto;
    }

    & > * {
        align-items: flex-end;

        &::before {
            top: 0;
        }
    }
}

.hook-tab-bottom-item() {
    border-top: @tab-item-border-width solid transparent;
    border-bottom: none;
}

.hook-tab-left() {
    padding: @tab-padding-vertical 0 @tab-padding-vertical 0;

    & > * {
        display: flex;
        justify-content: flex-end;
        padding: 0 @tab-vertical-item-padding-horizontal 0 @tab-vertical-item-padding-horizontal;
        margin: @tab-item-margin-vertical 0 @tab-item-margin-vertical 0;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: auto;
        right: 0;
        border-left: @tab-border-width solid @tab-border;
        border-bottom: none;
    }

}

.hook-tab-left-item() {
    border-right: @tab-item-border-width solid transparent;
    border-bottom: none;
}

.hook-tab-right() {
    padding: @tab-padding-vertical 0 @tab-padding-vertical 0;

    & > * {
        display: flex;
        justify-content: flex-start;   
        padding: 0 @tab-vertical-item-padding-horizontal 0 @tab-vertical-item-padding-horizontal;
        margin: @tab-item-margin-vertical 0 @tab-item-margin-vertical 0;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: auto;
        border-left: @tab-border-width solid @tab-border;
        border-bottom: none;
    }

}

.hook-tab-right-item() {
    border-left: @tab-item-border-width solid transparent;
    border-bottom: none;
}


// Miscellaneous
// ========================================================================

.hook-tab-misc() {

    .uk-tab .uk-dropdown { margin-left: (@tab-margin-horizontal + @tab-item-padding-horizontal) }

    .uk-tab-bottom > .uk-active > a {
        .hook-tab-item-active;

        &::before {
            top: 0;
            right: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: @tab-item-border-width;
            border-bottom-right-radius: @tab-item-border-width;
        }
    }

    .uk-tab-left > .uk-active > a {
        &::before {
            content: "";
            position: absolute;
            top: 0;            
            left: auto;
            right: 0;
            width: @tab-item-border-width;
            height: auto;
            border-top-right-radius: 0;
            border-top-left-radius: @tab-item-border-width;
            border-bottom-left-radius: @tab-item-border-width;
        }
    }

    .uk-tab-right > .uk-active > a {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: auto;
            left: 0;
            width: @tab-item-border-width;
            height: auto;
            border-top-left-radius: 0;
            border-top-right-radius: @tab-item-border-width;
            border-bottom-right-radius: @tab-item-border-width;
        }
    }

    .uk-tab-page-default, .uk-tab-page-primary, .uk-tab-page-secondary, .uk-tab-page-secondary-large  {
        &::before {
            display: none;
        }
        
        & > * {
            height: @tab-page-item-height;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: @tab-page-item-width;
            
            & > a {
                border-bottom: 0;
                text-transform: @tab-page-item-text-transform;
                font-size: @tab-page-item-font-size;
                font-weight: @tab-page-item-text-weight;
            }
        }

        & > .uk-active {   
            background-size: @tab-page-item-width-active @tab-page-item-height;
            background-position: 0 0;
            width: @tab-page-item-width-active;
            color: @tab-page-color;
            padding: 0;
            
            & > a::before {
                display: none;
            }
            
            &:nth-child(1) {
                margin-left: @tab-margin-horizontal;
                
                & > a {
                    padding-right: 25px;
                }        
            }
        }
    }
    .uk-tab-page-secondary-large  {
        & > .uk-active {   
            background-size: @tab-page-item-width-active-large @tab-page-item-height !important;
            width: @tab-page-item-width-active-large !important;
            
        }
    }
    
    .uk-tab-page-default {
        & > .uk-active {
            .svg-fill(@tab-page-image, "#000", @tab-page-default-active-background);

            &:nth-child(1) {
                .svg-fill(@tab-first-page-image, "#000", @tab-page-default-active-background);  
            }
        }
    }

    .uk-tab-page-primary {
        & > .uk-active {
            .svg-fill(@tab-page-image, "#000", @tab-page-primary-active-background);

            &:nth-child(1) {
                .svg-fill(@tab-first-page-image, "#000", @tab-page-primary-active-background);  
            }
        }
    }

    .uk-tab-page-secondary {
        & > .uk-active {
            .svg-fill(@tab-page-image, "#000", @global-light-grey-color-1);

            &:nth-child(1) {
                .svg-fill(@tab-first-page-image, "#000", @global-light-grey-color-1);  
            }
        }
    }

    .uk-tab-page-secondary-large {
        & > .uk-active {
            .svg-fill(@tab-second-page-image-large, "#000", @global-light-grey-color-1);

            &:nth-child(1) {
                .svg-fill(@tab-first-page-image-large, "#000", @global-light-grey-color-1);  
            }
        }
    }
}


// Inverse
// ========================================================================

@inverse-tab-border:                        @inverse-global-border;

.hook-inverse-tab() {

    &::before { border-color: @inverse-tab-border; }

}

.hook-inverse-tab-item() {}
.hook-inverse-tab-item-hover() {}
.hook-inverse-tab-item-active() { border-color: @inverse-global-primary-background; }
.hook-inverse-tab-item-disabled() {}