//
// Component: Icon
//
// ========================================================================


// Variables
// ========================================================================

@icon-link-active-color:                            @global-color;

// Style modifiers
// ========================================================================

//
// Link
//

.hook-icon-link() {
    &.uk-active svg {
        color: @icon-link-active-color;
    }
}

.hook-icon-link-hover() {}

.hook-icon-link-active() {}

//
// Button
//

.hook-icon-button() {
    transition: 0.1s ease-in-out;
    transition-property: color, background-color;
}

.hook-icon-button-hover() {}

.hook-icon-button-active() {}


// Miscellaneous
// ========================================================================

.hook-icon-misc() {}


// Inverse
// ========================================================================

.hook-inverse-icon-link() {}
.hook-inverse-icon-link-hover() {}
.hook-inverse-icon-link-active() {}

.hook-inverse-icon-button() {}
.hook-inverse-icon-button-hover() {}
.hook-inverse-icon-button-active() {}