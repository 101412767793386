//
// Component: Description list
//
// ========================================================================


// Variables
// ========================================================================

@description-list-term-font-size:                      @global-small-font-size;
@description-list-term-font-weight:                    normal;
@description-list-term-text-transform:                 uppercase;


// Component
// ========================================================================

.hook-description-list-term() {
    font-size: @description-list-term-font-size;
    font-weight: @description-list-term-font-weight;
    text-transform: @description-list-term-text-transform;
}

.hook-description-list-description() {}


// Style modifier
// ========================================================================

.hook-description-list-divider-term() {}


// Miscellaneous
// ========================================================================

.hook-description-list-misc() {}