// Name:            Description list
// Description:     Styles for description lists
//
// Component:       `uk-description-list`
//
// Modifiers:       `uk-description-list-divider`
//
// ========================================================================


// Variables
// ========================================================================

@description-list-term-color:                   @global-emphasis-color;
@description-list-term-margin-top:              @global-margin;

@description-list-divider-term-margin-top:      @global-margin;
@description-list-divider-term-border-width:    @global-border-width;
@description-list-divider-term-border:          @global-border;


/* ========================================================================
   Component: Description list
 ========================================================================== */

/*
 * Term
 */

.uk-description-list > dt {
    color: @description-list-term-color;
    .hook-description-list-term;
}

.uk-description-list > dt:nth-child(n+2) {
    margin-top: @description-list-term-margin-top;
}

/*
 * Description
 */

.uk-description-list > dd {
    .hook-description-list-description;
}


/* Style modifier
 ========================================================================== */

/*
 * Line
 */

.uk-description-list-divider > dt:nth-child(n+2) {
    margin-top: @description-list-divider-term-margin-top;
    padding-top: @description-list-divider-term-margin-top;
    border-top: @description-list-divider-term-border-width solid @description-list-divider-term-border;
    .hook-description-list-divider-term;
}


// Hooks
// ========================================================================

.hook-description-list-misc;

.hook-description-list-term() {}
.hook-description-list-description() {}
.hook-description-list-divider-term() {}
.hook-description-list-misc() {}