//
// Component: Table
//
// ========================================================================

// Variables
// ========================================================================

@table-header-cell-font-size: @global-small-font-size;
@table-header-cell-font-weight: 500;
@table-header-cell-color: @global-muted-color;

@table-row-active-background: transparent;
@table-hover-row-background: transparent;

@table-header-cell-font-size: @global-small-font-size;
@table-divider-border: @global-dark-grey-color-4;

//
// New
//

@table-row-border-radius: 15px;
@table-hover-row-column-background: @global-light-grey-color-2;

@table-striped-border-width: @global-border-width;
@table-striped-border: @global-border;
@list-border-radius-active:                     21px;
@table-row-height:                              70px;


// Component
// ========================================================================

.hook-table-header-cell() {
  // text-transform: uppercase;
}

.hook-table-cell() {
}

.hook-table-footer() {
}

.hook-table-caption() {
}

.hook-table-row-active() {
  border-radius: @global-border-radius;
}

// Style modifiers
// ========================================================================

.hook-table-divider() {
}

.hook-table-striped() {
  border-top: @table-striped-border-width solid @table-striped-border;
  border-bottom: @table-striped-border-width solid @table-striped-border;
}

.hook-table-hover() {
  &:not(.uk-table-sub-heading) {
    // set color to transparent to keep row size
    border-color: transparent;
  
    & > td {
      background-color: @table-hover-row-column-background;
      &:first-child {
        border-top-left-radius: @table-row-border-radius;
        border-bottom-left-radius: @table-row-border-radius;
      }
    
      &:last-child {
        border-top-right-radius: @table-row-border-radius;
        border-bottom-right-radius: @table-row-border-radius;
      }
    }
  
    // hide border from adjacent row
    & + tr {
      border-color: transparent;
    }
  }
}

// Size modifier
// ========================================================================

.hook-table-small() {
}

.hook-table-large() {
}

// Miscellaneous
// ========================================================================

.hook-table-misc() {
  .uk-table {
    background-color: @global-light-grey-color-1;
  }
  .uk-table tbody tr {
    transition: background-color 0.1s linear;
  }
  .uk-table th {
    color: @global-light-grey-color-2
  }
  .uk-table td {
    font-size: @global-font-size;
    font-weight: lighter;
    vertical-align: middle
  }
  .uk-table tr {
   height: @table-row-height;
  }

}

// Inverse
// ========================================================================

.hook-inverse-table-header-cell() {
}
.hook-inverse-table-caption() {
}
.hook-inverse-table-row-active() {
}
.hook-inverse-table-divider() {
}
.hook-inverse-table-striped() {
  border-top-color: @inverse-global-border;
  border-bottom-color: @inverse-global-border;
}
.hook-inverse-table-hover() {
}
