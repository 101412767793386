//
// Component: Nav
//
// ========================================================================

// Variables
// ========================================================================

@nav-item-padding-vertical: 25px;
@nav-item-padding-horizontal: 20px;
@nav-item-margin-vertical: 2px;

@nav-sublist-padding-vertical: 10px;
@nav-sublist-padding-left: 20px;
@nav-sublist-deeper-padding-left: 10px;
@nav-sublist-item-padding-vertical: 5px;

@nav-primary-item-font-size: @global-medium-font-size;

//
// New
//

@nav-default-font-size: @global-medium-font-size;

@nav-item-hover-background: @global-dark-grey-color-3;
@nav-item-active-background: @global-dark-grey-color-3;

@nav-item-border-radius: @global-border-radius;

@nav-toggle-image: "../../images/backgrounds/nav-toggle.svg";
@nav-toggle-size: @global-control-large-height;
@nav-toggle-background: @global-blue-color;
@nav-toggle-image-color: "#000";
@nav-toggle-image-color-active: "#FFF";
@nav-toggle-image-size: 32px;
@nav-toggle-shadow-color: fade(@global-blue-color, 60%);
@nav-toggle-border-radius: @global-border-large-radius;
@nav-toggle-content-width: 80px;
@nav-toggle-content-width-open: 340px;
@nav-toggle-shadow: 0px 0px 30px 0px @nav-toggle-shadow-color;
@nav-toggle-container-background: @global-dark-grey-color-1;
@nav-toggle-margin-bottom: 40px;

@nav-tab-toggle-image-open: "../../images/backgrounds/nav-tab-open.svg";
@nav-tab-toggle-image-close: "../../images/backgrounds/nav-tab-close.svg";
@nav-tab-toggle-image-multitab: "../../images/backgrounds/nav-tab-multitab.svg";
@nav-tab-toggle-image-width: 30px;
@nav-tab-toggle-image-height: 142px;
@nav-tab-toggle-background: @global-dark-grey-color-2;
@nav-tab-toggle-margin-top: 0px;
@nav-tab-toggle-content-width: 30px;
@nav-tab-toggle-content-width-open: 420px;
@nav-tab-toggle-arrow-color: @global-yellow-color;

@nav-user-color: @global-muted-color;
@nav-user-icon-size: 80px;
@nav-user-icon-background: @global-dark-grey-color-3;
@nav-user-icon-border-radius: @global-border-radius;

// Sublists
// ========================================================================

.hook-nav-sub() {
}

// Parent icon modifier
// ========================================================================

.hook-nav-parent-icon() {
  background: none;
}

// Header
// ========================================================================

.hook-nav-header() {
}

// Divider
// ========================================================================

.hook-nav-divider() {
}

// Default style modifier
// ========================================================================

.hook-nav-default() {
  font-size: @nav-default-font-size;
}

.hook-nav-default-item() {
}

.hook-nav-default-item-hover() {
  background: @nav-item-hover-background;
}
.hook-nav-default-item-active() {
  background: @nav-item-active-background;
}

.hook-nav-default-header() {
}

.hook-nav-default-divider() {
}

// Primary style modifier
// ========================================================================

.hook-nav-primary() {
}

.hook-nav-primary-item() {
}

.hook-nav-primary-item-hover() {
  background: @nav-item-hover-background;
}

.hook-nav-primary-item-active() {
  background: @nav-item-active-background;
}

.hook-nav-primary-header() {
}

.hook-nav-primary-divider() {
}

// Miscellaneous
// ========================================================================

.hook-nav-misc() {
  .uk-nav ul.uk-tab {
    padding: 0px @tab-padding-horizontal 0px @tab-padding-horizontal;
  }

  .uk-nav-default > li,
  .uk-nav-primary > li {
    margin: @nav-item-margin-vertical 0;
    & > a {
      border-radius: @nav-item-border-radius;
    }
  }
  .uk-nav-parent-icon > .uk-parent.uk-open > a::after {
    background: none;
  }
}

// Inverse
// ========================================================================

.hook-inverse-nav-parent-icon() {
}

.hook-inverse-nav-default-item() {
}
.hook-inverse-nav-default-item-hover() {
}
.hook-inverse-nav-default-item-active() {
}
.hook-inverse-nav-default-header() {
}
.hook-inverse-nav-default-divider() {
}

.hook-inverse-nav-primary-item() {
}
.hook-inverse-nav-primary-item-hover() {
}
.hook-inverse-nav-primary-item-active() {
}
.hook-inverse-nav-primary-header() {
}
.hook-inverse-nav-primary-divider() {
}

// Custom elements
// ========================================================================

.uk-nav-toggle-container {
  display: inline-flex;
  flex-direction: column;
  border-top-right-radius: 50px;
  background-color: @nav-toggle-container-background;

  .uk-nav-primary,
  .uk-nav-default {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > li {
      width: @nav-toggle-content-width;
      transition: width 0.3s;
      overflow: hidden;
      white-space: nowrap;

      .uk-icon {
        text-align: center;
        margin: 0 25px 0 0;
        width: 40px;
      }

      .uk-text {
        margin-left: 20px;
      }

      &.uk-nav-header {
        box-sizing: border-box;
        visibility: hidden;
        max-width: @nav-toggle-content-width;
      }

      &.uk-open > .uk-nav-sub {
        display: none;
      }
    }

    .uk-nav-user {
      display: flex;
      margin-top: auto;

      .uk-icon[uk-icon="icon: acr-menu-profile"] {
        display: flex;
        align-items: center;
        justify-content: center;
        height: @nav-user-icon-size;
        width: @nav-user-icon-size;
        background-color: @nav-user-icon-background;
        border-radius: @nav-user-icon-border-radius;
      }

      .uk-icon[uk-icon="icon: sign-out"] {
        margin-left: auto;
      }

      a {
        display: flex;
        align-items: center;
        padding: 0;
        flex: 1;
        color: @nav-user-color;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
          color: @nav-user-color;
        }
      }
    }
  }

  .uk-nav-toggle {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: @nav-toggle-size;
    height: @nav-toggle-size;
    min-height: @nav-toggle-size;
    background-color: @nav-toggle-background;
    -webkit-box-shadow: @nav-toggle-shadow;
    -moz-box-shadow: @nav-toggle-shadow;
    box-shadow: @nav-toggle-shadow;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: @nav-toggle-border-radius;
    margin: 0 0 @nav-toggle-margin-bottom 0px;
    .svg-fill(@nav-toggle-image, "#000", @nav-toggle-image-color);
    background-size: @nav-toggle-image-size @nav-toggle-image-size;
    outline: none;
    cursor: pointer;

    // IE9
    &::-ms-check {
      display: none;
    }

    &:checked {
      .svg-fill(@nav-toggle-image, "#000", @nav-toggle-image-color-active);

      & + .uk-nav-primary > li,
      & + .uk-nav-default > li {
        width: @nav-toggle-content-width-open;
        transition: width 0.3s;

        &.uk-nav-header {
          visibility: visible;
          max-width: @nav-toggle-content-width-open;
        }

        &.uk-open > .uk-nav-sub {
          display: inherit;
        }
      }
    }
  }
}

.uk-nav-tab-container {
  display: inline-flex;
  flex-direction: row-reverse;

  .uk-nav-tab {
    overflow: hidden;
    background-color: @nav-tab-toggle-background;
    width: @nav-tab-toggle-content-width;
    transition: width 0.3s;
    min-height: @nav-tab-toggle-image-height + @nav-tab-toggle-margin-top;

    & > * {
      display: none;
    }
  }

  .uk-nav-tab-toggle-white,
  .uk-nav-tab-toggle-multitab,
  .uk-nav-tab-toggle {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: @nav-tab-toggle-image-width;
    height: @nav-tab-toggle-image-height;
    margin: 0;
    margin-top: @nav-tab-toggle-margin-top;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    .svg-fill(@nav-tab-toggle-image-open, "#000", @nav-tab-toggle-background, "#FFF", @nav-tab-toggle-arrow-color);
    background-size: 100% 100%;
    outline: none;
    cursor: pointer;

    // IE9
    &::-ms-check {
      display: none;
    }

    &:checked {
      .svg-fill(@nav-tab-toggle-image-close, "#000", @nav-tab-toggle-background, "#FFF", @nav-tab-toggle-arrow-color);

      & + .uk-nav-tab {
        width: @nav-tab-toggle-content-width-open;
        transition: width 0.3s;

        & > * {
          display: block;
          min-width: @nav-tab-toggle-content-width-open;
        }
      }
    }
  }

  .uk-nav-tab-toggle-white {
    .svg-fill(@nav-tab-toggle-image-open, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");

    &:checked {
      .svg-fill(@nav-tab-toggle-image-close, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");
    }
  }

  .uk-nav-tab-toggle-multitab {
    .svg-fill(@nav-tab-toggle-image-multitab, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");

    &:checked {
      .svg-fill(@nav-tab-toggle-image-multitab, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");
    }
  }

  .uk-nav-tab-toggle-multitab-small {
    .svg-fill(@nav-tab-toggle-image-multitab, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");
    height: 70px;
    &:checked {
      .svg-fill(@nav-tab-toggle-image-multitab, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");
    }
  }

  .uk-nav-tab-toggle-multitab-medium {
    .svg-fill(@nav-tab-toggle-image-multitab, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");
    height: 100px;
    &:checked {
      .svg-fill(@nav-tab-toggle-image-multitab, "#000", @nav-tab-toggle-background, "#FFF", "#FFF");
    }
  }
}
