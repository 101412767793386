// Name:            Close
// Description:     Component to create a close button
//
// Component:       `uk-close`
//
// ========================================================================


// Variables
// ========================================================================

@close-color:                                   @global-muted-color;
@close-hover-color:                             @global-color;


/* ========================================================================
   Component: Close
 ========================================================================== */

/*
 * Adopts `uk-icon`
 */

.uk-close {
    color: @close-color;
    .hook-close;
}

/* Hover + Focus */
.uk-close:hover,
.uk-close:focus {
    color: @close-hover-color;
    outline: none;
    .hook-close-hover;
}


// Hooks
// ========================================================================

.hook-close-misc;

.hook-close() {}
.hook-close-hover() {}
.hook-close-misc() {}


// Inverse
// ========================================================================

@inverse-close-color:                           @inverse-global-muted-color;
@inverse-close-hover-color:                     @inverse-global-color;

.hook-inverse() {

    .uk-close {
        color: @inverse-close-color;
        .hook-inverse-close;
    }

    .uk-close:hover,
    .uk-close:focus {
        color: @inverse-close-hover-color;
        .hook-inverse-close-hover;
    }

}

.hook-inverse-close() {}
.hook-inverse-close-hover() {}
